.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 0px); /* calculate height of the screen minus the heading */
}
.map {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}
.fade {
  opacity: 0.5; 
}
.strike {
  text-decoration: line-through;
}
.sidePanel {
  /* display: none; */
  overflow: hidden;
}
.hide {
  display: none;
}
.disable {
  pointer-events: none;
  opacity: 0.3;
}
.marker {
  list-style-type: square;
  font-weight: bolder ;
  cursor: pointer;
  text-align: left;
  margin-left: 5px;
}
.markerItem {
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.markerItem li {
  display: inline-block;
  margin-left: 2px;
}
.markerItem input[type=color] {
  display: inline-block;
  width: 13%;
  height: 20px;
}
.markerItem input[type=range] {
  display: inline-block;
  width: 30%;
  right:0;
  height: 20px;
  margin-left: auto;
}
.factorPanel div {
  display: flex;
}
.factorPanel div input{
  margin-left: auto;
}
.factorPanel div input[type=color]{
  margin-left: unset;
}

.factorPanel div select{
  margin-left: auto;
}
.backgroundPanel div {
  display: flex;
}
.backgroundPanel div input{
  margin-left: auto;
}
.tooltip li {
  text-align: left;
  margin-left: 15px;
}
h3 {
  margin: 5px;
  cursor: pointer;
  color: #DDBB00;
}
.Roi h4 {
  margin: 2px;
}
input[type="color" i] {
  padding: 0px;
  margin: 0px;
}
.thin {
  border: none;
  height: 1px;
  background-color: #777; /* Modern Browsers */
}
h1 a {
  text-decoration: none;
}
h1 a:visited {
  text-decoration: none;
  color: white;
}
h1 a:link{
  text-decoration: none;
  color: white;
}
h1 a:hover{
  opacity: .7;
}
